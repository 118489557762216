.my-details {
    display: flex;
    justify-content: center;
    padding: 2rem 1rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
    gap: 1rem;
    height: 330px;

    &-default {
        width: 70%;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.03);

        p {
            font-size: 1rem;
            color: #606060;
            margin-top: 2rem;
        }
    }
}

.parking-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem 1rem;
    display: flex;
    gap: 1rem;

    &-info {
        border: 1px solid #dddfe2;
        padding: 1rem;
        border-radius: 10px;
        width: 53%;

        h2 {
            color: #bd3f32;
            font-size: 1.75rem;
            font-family: 'Montserrat';
            font-weight: 600;
            margin: 0 0 12px;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            gap: 1rem;
        }

        .parking-desc {
            color: #606060bf;
            font-size: 15px;
        }

        .head {
            color: #333;
            font-size: 1rem;
            font-family: 'Montserrat';

            .label {
                font-weight: 600;
            }
        }

        .location {
            display: flex;
            align-items: flex-end;
            padding-top: 0.5rem;

            span {
                font-size: 14px;
                color: #0099ff;
                font-family: 'Montserrat';
            }
        }
    }

    &-reviews {
        border: 1px solid #dddfe2;
        padding: 1rem;
        border-radius: 10px;
        width: 41%;
        height: 28vh;
        overflow: auto;

        h3 {
            font-family: 'Montserrat';
            font-size: 1.25rem;
            color: #bd3f32;
            font-weight: 600;
            margin-top: 0;
        }

        .review {
            display: flex;
            flex-direction: column;
            padding: 0.75rem 1rem 0.25rem;
            border-radius: 12px;
            margin-bottom: 1rem;
            background-color: #fff;
            box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
            cursor: pointer;

            .review-edit {
                color: #0099ff;
                display: none;
                padding: 4px;
                border-radius: 24px;
                margin: 0 4px;
                box-shadow: 0px 0px 16px rgb(0 0 0 / 15%);
            }

            .review-delete {
                display: none;
                color: #bd3f32;
                padding: 4px;
                border-radius: 24px;
                margin: 0 4px;
                box-shadow: 0px 0px 16px rgb(0 0 0 / 15%);

            }

            &:hover {

                .review-edit,
                .review-delete {
                    transition: 0.5s;
                    display: block;
                }
            }

            .user-detail {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                padding-bottom: 0.5rem;
                align-items: center;

                .review-time {
                    font-size: 10px;
                    color: #606060;
                    font-style: italic;
                }

                .user-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    .user {
                        font-size: 2rem;
                        color: rgba(0, 0, 0, 0.2);
                        width: 32px;
                        height: 32px;
                    }

                    .name {
                        font-size: 14px;
                        color: #bd3f32;
                        font-weight: 600;
                    }
                }

                .stars {
                    color: #fff;
                    padding: 3px;
                    font-size: 14px;
                    border-radius: 0.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    height: 1.25rem;
                    min-width: 2.25rem;

                    .star {
                        font-size: 16px;
                        width: 16px;
                        height: 16px;
                    }

                    .rating {
                        font-weight: 600;
                    }
                }

            }

            .review-detail {
                color: #606060;
                font-size: 15px;
            }

        }


    }

}

.features-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    gap: 1rem;

    &-item {
        border: 1px solid #dddfe2;
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        width: 53%;
        display: grid;
        grid-template-columns: auto auto auto;

        .data {
            display: flex;
            align-items: center;
            grid-gap: .5rem;
            gap: 0.5rem;
        }
    }

    &-info {
        border: 1px solid #dddfe2;
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        width: 41%;
        display: grid;
        grid-template-columns: auto auto auto;

        .feature-1,
        .feature-2 {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;
            gap: 1rem
        }

        span {
            display: flex;
        }

        .label {
            font-weight: 600;
            font-size: 1rem;
            color: #bd3f32;
            margin-right: 0.5rem;
        }
    }
}

.slot-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    gap: 1rem;

    .booked-slots {
        width: 43%;
    }

    .available-slots {
        width: 57%;
    }

    .booked-slots,
    .available-slots {
        border: 1px solid #dddfe2;
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        max-height: 22vh;
        overflow: auto;

        h3 {
            font-family: 'Montserrat';
            font-size: 1.25rem;
            color: #bd3f32;
            font-weight: 600;
            margin-top: 0;
        }

        .slot-time {
            display: flex;
            gap: 10px;
            align-items: center;

            .label {
                font-weight: 600;
            }

            .start,
            .end {
                display: inline-block;
                padding: 0.5rem 1rem;
                border: 1px solid #dddfe2;
                border-radius: 30px;

            }

            .price {
                color: #bd3f32;
            }

            .primary-btn {
                width: 100px;
                background-color: #09f;
                color: #fff;
                border: none;
                padding: 12px 24px;
                font-family: Montserrat;
                font-size: 1rem;
                border-radius: 1.5rem;
                cursor: pointer;
            }

        }
    }
}

.parking-summary,
.parking-profile {
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    background: #0099ff08;
    box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);

    &-card {
        box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;
        width: 500px;
        text-align: center;
        border-radius: 20px !important;
        padding: 2rem 2rem 2rem 2rem;
        margin-right: 2rem;

        h2 {
            font-family: 'Montserrat';
            font-size: 1.5rem;
            color: #bd3f32;
            font-weight: 600;
            margin-bottom: 2rem;
        }

        &-item {
            p {
                font-size: 1rem;
                font-family: 'Montserrat';

                .label {
                    font-weight: 400;
                    font-size: 14px;
                    color: #606060;
                }

                &:nth-child(1n) {
                    display: flex;
                    justify-content: space-between;
                    padding: 2px;
                    font-weight: 500;
                    align-items: center;
                }
            }

            .discount-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .label {
                    font-weight: 400;
                    font-size: 14px;
                    color: #606060;
                    font-family: 'Montserrat';
                }

                .discount-coupon {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    align-items: flex-end;

                    .wrapper {
                        // width: 60%;
                        display: flex;

                        &-input {
                            border-radius: 20px;
                            font-family: 'Montserrat';
                            font-size: 1rem;
                            color: #606060;
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            margin-right: -2rem;
                            width: 200px;
                            padding: 0 1rem;

                            &::placeholder {
                                font-size: 13px;
                                color: rgba(0, 0, 0, 0.5);
                            }
                        }

                        .apply-btn {
                            width: 100px;
                            background-color: #09f;
                            padding: 12px 10px;
                            border: none;
                            font-family: Montserrat;
                            font-size: 1rem;
                            color: #fff;
                            border-radius: 1.5rem;
                            cursor: pointer;
                        }
                    }

                    .error {
                        font-size: 12px;
                        color: #bd3f32;
                        font-style: italic;
                        margin-bottom: 0;
                        margin-top: 4px;
                        padding: 0;
                    }
                }

            }
        }
    }

    .payment-section {
        min-width: 400px;
        height: fit-content;
        text-align: center;
        padding: 20px;

        &-item {
            display: flex;
            flex-direction: column;

            &-button {
                background-color: #09f;
                padding: 12px 10px;
                border: none;
                font-size: 1rem;
                color: #fff;
                border-radius: 1.5rem;
                cursor: pointer;
            }
        }
    }

    .dialog {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;

        .container {
            width: 400px;
            height: 500px;
            background-color: #fff;
            display: flex;
            align-items: center;
            border-radius: 16px;
            box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
            flex-direction: column;
            gap: 1.5rem;

            p {
                font-weight: 600;
                font-size: 3rem;
                text-align: center;
                color: rgba(52, 183, 89);
                line-height: 48px;
                margin: 0;
            }

            img {
                width: 70%;
                margin-bottom: -4rem;
            }

            .primary-btn {
                width: 100px;
                background-color: #09f;
                color: #fff;
                border: none;
                padding: 12px 24px;
                font-family: Montserrat;
                font-size: 1rem;
                border-radius: 1.5rem;
                cursor: pointer;
            }
        }
    }

}

.parking-profile {
    &-card{
        max-width: 300px;
        .label {
            font-weight: bold !important;
        }
    }   
}

.history {
    text-align: center;

    .primary-btn {
        background-color: #09f;
        color: #fff;
        border: none;
        padding: 12px 24px;
        font-family: Montserrat;
        font-size: 1rem;
        border-radius: 1.5rem;
        cursor: pointer;
    }
}
.swiper-button-next:after, .swiper-button-prev:after{
    color: white;
    font-weight: bold;
}