$text-color: #000000;
$light-text :#ffffff;
$blue_text: #0099ff;
$primary-color: #BD3F32;

.header {
    &-logo {
        height: 40px;
    }

    &-link {
        display: flex;
        gap: 30px;
        align-items: center;

        a {
            text-decoration: none;
            color: $text-color;
            font-size: 14px;
        }

        .active {
            color: #fff !important;
            border-radius: 50px;
            font-weight: 600;
            background-color: $blue_text;
            transition: border-radius 3s, background-image 3s;
            padding: 10px 20px;
        }

    }
}

.backgroud {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(8, 83, 156, 0)), url('../Images/car-parking.jpg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    height: 800px;
    padding: 100px 200px 0;
    display: flex;
    // flex-flow: wrap;
    justify-content: space-between;

    &-intro {
        color: $light-text;

        &-title {
            display: flex;
            flex-direction: column;
            font-size: 42px;
            line-height: 64px;
            font-weight: bold;
            letter-spacing: 0.2px;

            .blue {
                font-size: 75px;
                color: $blue_text;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            }
        }

        &-desc {
            font-size: 1rem;
            line-height: 22px;
            letter-spacing: .3px;
        }
    }

    @media screen and (max-width: 1600px) {
        padding: 30px;
    }
}

.auth-backgroud {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    padding: 100px 16px 16px;
    display: flex;
    justify-content: center;
    background-color: #0099ff08;
}

.auth-card {
    min-width: 1000px;
    display: flex;
    background: #fff;
    box-shadow: 0 7px 29px 0 #64646f33 !important;
    border-radius: 25px !important;
    gap: 20px;
    padding: 0 30px;
    align-items: flex-end;
    justify-content: space-between;

    &-content {
        padding: 2rem;
        width: 50%;

        &-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            &-head {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 2rem;
                font-weight: 600;
                color: #bd3f32;

                &-desc {
                    font-size: 12px;
                    opacity: .6;
                    color: #000;
                    line-height: 48px;
                }
            }
        }

        &-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-gap: 10px;
            gap: 10px;
            cursor: pointer;

            .blue {
                color: #09f;
                font-size: 12px;
                font-weight: 600;
            }

            .sign-up-img {
                width: 134px;
                vertical-align: -webkit-baseline-middle;
                margin-left: 0.5rem;
            }
        }
    }
}

.form-card {
    border-radius: 25px !important;
    padding: 30px;
    border: 1px solid #88909a66;
    border-top: 10px solid $blue_text;

    &-content {
        padding: 30px 0 !important;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;

    img {
        width: 70px;
    }
}

.no-parking-found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.5rem;
        color: #bd3f32;
        font-family: 'Montserrat';
    }
}

.map-location {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;

    span {
        font-size: 1rem;
        color: #0099ff;
    }

}

.MuiInputBase-input {
    height: 15px !important;
}

.sidebar {
    min-width: 250px;
    min-height: 100vh;
    background-color: #fff;
    box-shadow: 0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933;
    padding: 1rem;

    .Mui-selected {
        color: #ffffff !important;
        border-radius: 50px !important;
        font-weight: 600 !important;
        background-color: #0099ff !important;
        transition: 3s border-radius !important;
    }
}