.marker {
  background-color: #d83b01;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;
  border: 1px solid transparent;
  transform: rotate(-45deg);

  &-text {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
}

.map-marker {
  background-image: url('../Images/marker3.png');
  background-repeat: no-repeat;
  color: #fff;
  height: 60px;
  width: 60px;
  position: relative;
 }

.hover {
  background-color: #0078d4;
}