.cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 65%;
    padding: 0 1rem;
    overflow: auto;

    .head {
        font-size: 1.75rem;
        color: #bd3f32;
        font-weight: 600;
    }

    &-item {
        box-shadow: 0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933;
        border: 1px solid #dddfe2;
        border-radius: 8px;
        transition: box-shadow .2s ease-in-out;
        overflow: initial !important;

        &-contents {
            display: flex;
            padding: 0 !important;

            .gallery {
                width: 350px;
                height: 240px;
                overflow: hidden;
                background: rgba(0, 0, 0, 0.05);

                &-img {
                    width: 100%;
                    height: 100%;
                }

                .default-img {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: rgba(0, 0, 0, 0.03);

                    p {
                        font-size: 1rem;
                        color: #606060;
                        margin-top: 2rem;
                    }
                }
            }


            .details {
                padding: 1.5rem;
                width: 50%;
                font-family: 'Montserrat';
                font-size: 1rem;

                .name {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #bd3f32;
                    margin: 0 0 12px;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .avg {
                        color: #fff;
                        padding: 3px;
                        font-size: 17px;
                        border-radius: 0.6rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        grid-gap: 4px;
                        gap: 4px;
                        height: 1.25rem;
                        width: 2.5rem;

                        mat-icon {
                            font-size: 16px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .booking-time {
                    display: flex;
                    gap: 1rem;

                    .time {
                        border-radius: 10px;
                        padding: 1rem;
                        border: 1px solid #dddfe2;
                        font-family: 'Montserrat';
                        font-size: 1rem;
                        color: #333;
                        background-color: #fff;
                        display: block;
                        box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);

                        .label {
                            font-weight: 600;
                            display: block;
                        }

                    }
                }

                .address {
                    color: #606060;

                    .label {
                        font-weight: 600;
                    }
                }

                .time {
                    display: flex;
                    font-family: 'Montserrat';
                    font-size: 1rem;
                    // color: #bd3f32;
                    align-items: center;
                    margin-top: 0.5rem;

                    mat-icon {
                        // font-size: 1.75rem;
                        color: #bd3f32;
                        margin-right: 0.5rem;
                    }

                    .label {
                        color: #333;
                        font-weight: 600;
                        display: inline-block;
                        margin-right: 0.5rem;
                    }
                }

                .location {
                    display: flex;
                    align-items: flex-start;
                    padding-top: 0.5rem;

                    mat-icon {
                        font-size: 1rem;
                        color: #0099ff;
                    }

                    span {
                        font-size: 12px;
                        color: #0099ff;
                        margin-left: -5px;
                    }
                }

                .features {
                    margin-top: 0.5rem;

                    span {
                        border: 1px solid #dddfe2;
                        padding: 0px 8px;
                        margin: 2px;
                        display: inline-block;
                        font-size: 12px;
                    }
                }
            }

            .actions {
                padding: 1.5rem;
                width: 22%;
                border-left: 1px solid #dddfe2;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                gap: 1rem;

                .rating {
                    text-align: end;
                    margin-bottom: -20px;

                    mat-icon {
                        font-size: 1.6rem;
                        color: #ffa726;
                    }

                    p {
                        font-family: 'Montserrat';
                        font-size: 0.75rem;
                        color: #606060;
                        margin-top: -10px;
                        margin-bottom: 16px;
                    }
                }

                .euro {
                    color: #bd3f32;
                    font-size: 2em;
                    font-weight: 600;
                    font-family: 'Montserrat';
                    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);

                    .subinfo {
                        display: block;
                        font-size: 10px;
                        color: #606060;
                    }

                }

                .badge {
                    padding: 0.2rem 0.5rem;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    font-family: 'Montserrat';
                }

                .primary-btn {
                    width: 100px;
                    background-color: #09f;
                    color: #fff;
                    border: none;
                    padding: 12px 24px;
                    font-family: Montserrat;
                    font-size: 1rem;
                    border-radius: 1.5rem;
                    cursor: pointer;
                }

                .green-btn {
                    background-color: #34b759;
                }
            }

            .history-actions {
                padding: 1.5rem;
                width: 25%;
                border-left: 3px dotted #dddfe2;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                position: relative;
                // gap: 1rem;

                .billing-date {
                    font-style: italic;
                    font-size: 10px;
                    position: absolute;
                    right: 8px;
                    top: 4px;
                }

                .discount,
                .tax,
                .total,
                .euro {
                    font-family: 'Montserrat';
                    font-size: 1rem;
                    margin-bottom: 4px;
                    color: #bd3f32;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .label {
                        font-weight: 600;
                        color: #333;
                    }
                }

                .discount {
                    font-size: 14px;
                }

                .tax {
                    font-size: 14px;

                    .rate {
                        font-size: 10px;
                        color: #606060;
                    }
                }

                .total {
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding-top: 8px;
                    margin-top: 16px;
                    border-top: 1px solid #dddfe2;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .label {
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }

                .booking-status {
                    font-family: 'Montserrat';
                    font-size: 1rem;
                    color: #333;
                    text-align: end;

                    .label {
                        font-weight: 600;
                    }
                }

                .rating {
                    text-align: end;
                    margin-bottom: -20px;

                    mat-icon {
                        font-size: 1.6rem;
                        color: #ffa726;
                    }

                    p {
                        font-family: 'Montserrat';
                        font-size: 0.75rem;
                        color: #606060;
                    }
                }

                .euro {
                    // color: #bd3f32;
                    font-size: 14px;
                    font-family: 'Montserrat';
                    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);

                    .label {
                        font-size: 14px;
                    }
                }

                .primary-btn {
                    width: 100px;
                    background-color: #09f;
                    color: #fff;
                    border: none;
                    padding: 12px 24px;
                    font-family: Montserrat;
                    font-size: 1rem;
                    border-radius: 1.5rem;
                    cursor: pointer;
                }

                .green-btn {
                    background-color: #34b759;
                }
            }
        }
    }

    .no-parking-found {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60vh;

        p {
            font-size: 1.5rem;
            color: #bd3f32;
            font-family: 'Montserrat';
        }
    }

}

.register-card {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 2rem 3rem;
    border-radius: 12px !important;
    box-shadow: 0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933 !important;

    &-head {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        color: #bd3f32;
        margin: 0;
    }

    &-preview {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;

        &-box {
            position: relative;

            .upload-img {
                width: 195px;
                height: 195px;
                border-radius: 0.5rem;
                box-shadow: 0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

            .cancel {
                cursor: pointer;
                color: #bd3f32;
                position: absolute;
                margin: -12px 0 0 -12px;
            }
        }
    }
}

.tab {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #bd3f32 !important;
    text-transform: none !important;
}