body, html {
  min-height: 100vh;
  width: 100%;
}
::-webkit-scrollbar {
  width: 8px;
 }

::-webkit-scrollbar-thumb {
 border-radius: 20px;
  background-color: #bd3f32;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bd3f32b0;
}