.dashboard-count {
    width: 330px;
    // height: 140px;
    background-color: #fff;
    border-radius: 1rem !important;
    padding: 2rem;
    box-shadow: 0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
        color: #bd3f32;
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 0;
    }

    &-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &-total {
            font-size: 3rem;
            color: #bd3f32;
            font-weight: 300;
        }
    }
}
.upcoming {
    height: 23vh;
    overflow: scroll;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
    background-color: #fff;

    .title {
        color: #bd3f32;
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 0;
    }
    &-wrapper {
        padding: 1rem 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: flex;
        gap: 1rem;
        align-items: center;

        &:last-child {
            border-bottom: none;
        }

        .address {
            display: flex;
            flex-direction: column;

            .name {
                font-size: 1rem;
                font-weight: 600;
                color: #bd3f32;
                font-family: 'Montserrat';
            }
            .location {
                font-size: 1rem;
                color: #0099ff;
                display: flex;
                align-items: center;

                mat-icon {
                    font-size: 1rem;
                     width: auto;
                     height: auto;
                }
            }    
        }
        .time {
            padding: 0.5rem 1rem;
            border: 1px solid #dddfe2;
            color: #333;
            font-family: 'Montserrat';
            font-size: 1rem;
            border-radius: 1rem;

            .label {
                font-weight: 600;
            }
        }
    }
}
.charts{
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
    background-color: #fff;
}