/* montserrat-200 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url(/fonts/montserrat/montserrat-v25-latin-200.eot);
    /* IE9 Compat Modes */
    src: local(''),
        url(/fonts/montserrat/montserrat-v25-latin-200.eot?#iefix) format('embedded-opentype'),
        /* IE6-IE8 */
        url(/fonts/montserrat/montserrat-v25-latin-200.woff2) format('woff2'),
        /* Super Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-200.woff) format('woff'),
        /* Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-200.ttf) format('truetype'),
        /* Safari, Android, iOS */
        url(/fonts/montserrat/montserrat-v25-latin-200.svg#Montserrat) format('svg');
    /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/montserrat/montserrat-v25-latin-regular.eot);
    /* IE9 Compat Modes */
    src: local(''),
        url(/fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix) format('embedded-opentype'),
        /* IE6-IE8 */
        url(/fonts/montserrat/montserrat-v25-latin-regular.woff2) format('woff2'),
        /* Super Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-regular.woff) format('woff'),
        /* Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-regular.ttf) format('truetype'),
        /* Safari, Android, iOS */
        url(/fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat) format('svg');
    /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/montserrat/montserrat-v25-latin-600.eot);
    /* IE9 Compat Modes */
    src: local(''),
        url(/fonts/montserrat/montserrat-v25-latin-600.eot?#iefix) format('embedded-opentype'),
        /* IE6-IE8 */
        url(/fonts/montserrat/montserrat-v25-latin-600.woff2) format('woff2'),
        /* Super Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-600.woff) format('woff'),
        /* Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-600.ttf) format('truetype'),
        /* Safari, Android, iOS */
        url(/fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat) format('svg');
    /* Legacy iOS */
}

/* montserrat-900 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(/fonts/montserrat/montserrat-v25-latin-900.eot);
    /* IE9 Compat Modes */
    src: local(''),
        url(/fonts/montserrat/montserrat-v25-latin-900.eot?#iefix) format('embedded-opentype'),
        /* IE6-IE8 */
        url(/fonts/montserrat/montserrat-v25-latin-900.woff2) format('woff2'),
        /* Super Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-900.woff) format('woff'),
        /* Modern Browsers */
        url(/fonts/montserrat/montserrat-v25-latin-900.ttf) format('truetype'),
        /* Safari, Android, iOS */
        url(/fonts/montserrat/montserrat-v25-latin-900.svg#Montserrat) format('svg');
    /* Legacy iOS */
}

body {
    margin: 0;
    font-family: 'Montserrat' !important;
    font-weight: 400;

    .MuiPaper-root,
    .MuiTypography-root,
    .MuiInputBase-input,
    .MuiInputLabel-root,
    .MuiButton-root,
    .MuiTab-root,
    .MuiMenuItem-root {
        font-family: 'Montserrat'
    }
}